.leftSideMenuContainer {
  padding: 0px !important;
  background-color: #ffffff;
  border-top: 1px solid #eaedf3;
}

.leftSideMenu {
  margin-top: 25px;
}

.leftSideMenuItem {
  border-left: solid 3px #fff;
  color: #3e3f42;
  font-size: 14px;
  /*font-family: Montserrat;*/
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  vertical-align: middle;
}

.leftSideMenuItem.selected {
  border-color: #1b8c96;
  background-color: #f6fdfd;
}

.leftSideMenuItem.header {
  color: #9ea0a5;
  text-transform: uppercase;
}

.leftSideMenuItem a {
  color: #3e3f42 !important;
  text-decoration: none;
}

.currentTeamContainer {
  display: flex;
  margin-top: 15px;
}

.currentTeamName {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 18px;
  height: 22px;
  background: #eaedf3;
  border-radius: 16px;
  color: #344054;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
  text-transform: capitalize;
}

.team {
  border-top: 1px solid #eaedf3;
  font-size: 11px;
}

.currentTeam {
  text-decoration: underline;
  font-weight: 200;
}
