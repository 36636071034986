.unsubscribeButton {
  border: 1px solid #1b8c96;
  cursor: pointer;
  color: #1b8c96;
  background: white;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  margin: 20px auto;
  display: block;
}

.successMessage {
  color: #1b8c96;
  font-weight: bold;
  margin: 20px auto !important;
  display: block;
  width: 50px;
}
